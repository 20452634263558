/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint no-nested-ternary: "error" */
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  createContext, useState, useContext, useMemo,
} from 'react';
import { Alert, Image, Row, Col, Button } from 'react-bootstrap';

const AlertContext = createContext(undefined);
export const useAlert = () => {
  const alertValue = useContext(AlertContext);
  if (alertValue === undefined) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return alertValue.setAlert;
};

/* alert = {
  message:[REQUIRED] ERROR MESSAGE
  type:[OPTIONAL] ONLY pass a type in if you expect a critical or passive Alert,
  variant:[OPTIONAL] (DEFAULT = 'warning') Determines which alert you will be using ('success' || 'warning' || 'danger'),
  position:[OPTIONAL] (DEFAULT = 'fixed') USE ('Fixed' || 'Relative'),
  time:[OPTIONAL] (DEFAULT = 6 Seconds) Add a number for how long you want it to be displayed (example: 6000)
  dropDownMessage:[OPTIONAL] Add Message here to enable the dropdown. (try to use API error Messages),
  dropDownCode:[OPTIONAL] OPTIONAL add API ERROR CODE,
  messageArray:[OPTIONAL] (example: ['test1', 'test2']). Only add IF you are trying to display bullet points,
} */
const RenderAlert = () => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const { alert, setAlert, error } = useContext(AlertContext);
  let AlertComponent = null;
  if (alert) {
    if (!alert.type && alert.message) {
      AlertComponent = (
        <div className="d-flex justify-content-center">
          <Alert key="warning" variant={alert.variant || 'warning'} className={`position-${alert.position || 'fixed'} text-center mt-32 mb-0 p-0 py-8 w-50`} style={{ zIndex: 100 }}>
            <div className="d-flex justify-content-end bottom-0 mt-8 me-16">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setAlert(null)}
              />
            </div>
            <div className="fw-700" style={{ marginTop: '-1rem' }}>
              {!alert?.dropDownMessage ? (
                <>
                  <Image src={`/images/${alert.variant || 'warning'}-alert-icon.svg`} className="me-8" />
                  {alert.message}
                </>
              ) : (
                <>
                  <Row>
                    <Col xs={3}>
                      <Button type="button" variant={`outline-${alert.variant || 'warning'}`} className="border-0 h-100 my-auto" onClick={() => setDropDownOpen(!dropDownOpen)}>
                        {dropDownOpen ? 'Hide Details' : 'View Details' }
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Image src={`/images/${alert.variant || 'warning'}-alert-icon.svg`} className="me-8" />
                      {alert.message}
                    </Col>
                  </Row>
                  {dropDownOpen && (
                    <div className="mb-8">
                      <hr className={`border-${alert.variant || 'warning'}`} />
                      Message:
                      {' '}
                      {alert.dropDownMessage}
                      {' '}
                      {alert?.dropDownCode && (
                      <>
                        Code:
                        {' '}
                        {alert.dropDownCode}
                      </>
                      )}
                    </div>
                  )}
                </>
              )}
              {alert?.messageArray && (
                <div className="mb-8">
                  <hr className={`border-${alert.variant || 'warning'}`} />
                  <ul className="fw-400 text-start w-50 mx-auto">
                    {alert?.messageArray && alert?.messageArray.map((message) => (
                      <li className="">{message}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Alert>
        </div>
      );
    }

    if (alert.type === 'critical') {
      window.alert(alert.message);
      console.error(error);
    }

    if (alert.type === 'passive') {
      console.error(error);
    }
  }

  if (alert?.time !== false) {
    setTimeout(() => {
      setAlert(null);
    }, alert?.time || 6000);
  }

  return AlertComponent;
};

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);
  const value = useMemo(() => ({ alert, setAlert }), [alert]);
  return (
    <AlertContext.Provider value={value}>
      {alert && <RenderAlert />}
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
