/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoadingSignal, isLoggedInSignal, userDataSignal } from '../../../libs/signals';
import AppContainer from '../../AppContainer';

const OGRoute = ({ component: Component, ...rest }) => {
  if (!isLoadingSignal.value && (isLoggedInSignal?.value || window.localStorage.getItem('loggedIn')) && userDataSignal?.value?.userData.uid) {
    if (userDataSignal?.value?.userData?.clientId === 'v3C5vOJ50dE0dT79TPFk') { // OG ID
      return (
        <Route
          {...rest}
          render={(props) => (
            <AppContainer isLoggedIn>
              <Component {...props} />
            </AppContainer>
          )}
        />
      );
    }
    return <Redirect to="/login" />;
  }
  return '';
};

export default OGRoute;
