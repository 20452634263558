import { HttpMethod } from '../../../../../../constants';

const version = 'v2';
const baseUrl = 'organizations';
const docLink = `docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'organization_object',
  },
  get: {
    id: 'organnization_retrieval',
  },
  search: {
    id: 'organization_search',
  },
};

const model = {
  header: 'Grant Seeking Organizations',
  title: 'The Organization Object',
  object: endpointIds.object.id,
  link: `/docs/api/${version}#${endpointIds.object.id}`,
};

const attributes = {
  id: { type: 'int', description: 'The organization\'s unique id.' },
  name: { type: 'string', description: 'The organization\'s name.' },
  city: { type: 'string', description: 'The city the organization is located at.' },
  country: { type: 'string', description: 'The country the organization is located at.' },
  descriptionProp: { type: 'string', description: 'A short description about the organization.' },
  ein: { type: 'string', description: 'The organization\'s ein.' },
  fundingReceived: { type: 'boolean', description: 'True if the organization has received funding. False otherwise.' },
  fundingRequested: { type: 'int', description: 'The amount of funding that the organization is requesting.' },
  isBusiniess8aDevelopment: { type: 'boolean', description: 'True if the organization is a business 8a development. False if otherwise.' },
  isHubZoneOwned: { type: 'boolean', description: 'True if the organization is hubzone owned. False otherwise.' },
  isSmallBusiness: { type: 'boolean', description: 'True if the organization is a small business. False if otherwise.' },
  isWomanOwned: { type: 'boolean', description: 'True if the organization is woman owned. False if otherwise.' },
  isVeteranOwned: { type: 'boolean', description: 'True if the organization is veteran owned. False if otherwise.' },
  logoUrl: { type: 'string', description: 'The url to organization\'s logo.' },
  numberOfEmployees: { type: 'string', description: 'The url to organization\'s logo.' },
  orgType: {
    type: 'string',
    description: (
      <>
        {'The organization\'s type.'}
        <span> </span>
        <a href={`/docs/api/${version}#filter_object`}>
          See Filter Options to retrieve all possible options.
        </a>
      </>
    ),
  },
  samNumber: { type: 'string', description: 'The organizations\'s SAM number.' },
  size: { type: 'int', description: 'The size of the organization.' },
  state: { type: 'string', description: 'The state the organization is located at.' },
  uniqueEntityIdentifier: { type: 'string', description: 'The organization\'s unique entity identifier, previously known as the DUNS number.' },
  streetAddress: { type: 'string', description: 'The organization\'s street address.' },
  totalFundingReceived: { type: 'string', description: 'The total funding the organization has received thus far.' },
  website: { type: 'string', description: 'The organization\'s website.' },
  zipCode: { type: 'string', description: 'The zipcode the organization is located at.' },
  industries: {
    type: 'array of strings',
    description: (
      <>
        An array of industries the organization is associated with.
        <span> </span>
        <a href={`/docs/api/${version}#filter_object`}>
          See Filter Options to retrieve all possible options.
        </a>
      </>
    ),
  },
  naicsCodes: {
    type: 'array of strings',
    description: (
      <>
        An array of naics codes that the organization is associated with.
        <span> </span>
        <a href={`/docs/api/${version}#filter_object`}>
          See Filter Options to retrieve all possible options.
        </a>
      </>
    ),
  },
  socialImpactCategories: {
    type: 'array of string',
    description: (
      <>
        An array of social impacts that the organization is associated with.
        <span> </span>
        <a href={`/docs/api/${version}#filter_object`}>
          See Filter Options to retrieve all possible options.
        </a>
      </>
    ),
  },
  technologies: {
    type: 'array of string',
    description: (
      <>
        An array of technologies that the organization is associated with.
        <span> </span>
        <a href={`/docs/api/${version}#filter_object`}>
          See Filter Options to retrieve all possible options.
        </a>
      </>
    ),
  },
};

const endpoints =
{
  get: {
    id: endpointIds.get.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.get.id}`,
    endpoint: `/${baseUrl}/{id}`,
    example: `/${baseUrl}/2269`,
    title: 'Retrieve an Organizaiton',
    description: 'Retrieves an organization by the organization object id',
    returnContent: 'Returns the Organization object if the call succeeded',
  },
  search: {
    id: endpointIds.search.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.search.id}`,
    endpoint: `/${baseUrl}`,
    example: `/${baseUrl}?query=education&facetFilters=["technologies:Aerospace"]`,
    title: 'Search Organizations',
    description: 'Searches and returns an array of organizations from the OpenGrants database via pagination',
    returnContent: 'Returns a Pagination object with the data property containing an array of Organization objects.',
    queryParameters: {
      query: { type: 'optional', description: 'Search the organization name and description for any mention of the query text.' },
      page: { type: 'optional', description: 'Retrieve the organizations from the specifed page.' },
      filterFacets: {
        type: 'optional',
        description: (
          <>
            Filter organizations by attributes
            <br />
            <br />
            Possible facet attributes are country, industries, naicsCodes (code only), orgType,
            socialImpactCategories, state, and technologies
            <br />
            <br />
            To filter by text value, set facetFilters=[
            {'<options>'}
            ] where
            {' <options> '}
            is one or a combination of the following:
            <ul>
              <li>&quot;attribute:value&quot; for single filtering</li>
              <li>
                &quot;attribute:value&quot;,&quot;attribute:value&quot;
                for filtering by AND
              </li>
              <li>
                [&quot;attribute:value&quot;,&quot;attribute:value&quot;]
                for filtering by OR
              </li>
            </ul>
            Attribute is one of the possible facet attributes mentioned
            above, and value is the value you would like to filter by.
            Note that the attribute and the value are match-case.
            This means that the attribute and value must match the options
            exactly to get the expected returns.
            <span> </span>
            <a href={`/docs/api/${version}#filter_object`}>
              See Filter Options to retrieve possible options for each of the attribute
            </a>
            <br />
            <br />
            <div className="ps-16">
              Example (filter by single facet):
              <br />
              facetFilters=[&quot;technologies:Energy&quot;]
              <br />
              <br />
              Example (filter by multiple facet AND):
              <br />
              facetFilters=[&quot;technologies:Aerospace&quot;,
              &quot;orgType:Nonprofit&quot;]
              <br />
              <br />
              Example (filter by multiple facet OR):
              <br />
              facetFilters=[[&quot;technologies:Aerospace&quot;,
              &quot;orgType:Nonprofit&quot;]]
            </div>
          </>
        ),
      },
    },
  },
};

export {
  model,
  attributes,
  endpoints,
};
