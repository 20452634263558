import Response from '../Components/Response';
import Section from '../../Components/Section';

const SummarySection = ({ header, summary, endpoints, title = 'BASE_URL' }) => {
  const sectionA = (
    <>
      <h3>{header}</h3>
      {summary}
    </>
  );

  const sectionB = endpoints ?
    (
      <Response
        title={title}
      >
        <ul className="list-unstyled">
          {Object.entries(endpoints).map(([, endpoint]) => (
            <li><a href={endpoint.link} className="text-decoration-none text-black">{endpoint.endpoint}</a></li>
          ))}
        </ul>
      </Response>
    ) :
    null;

  return (
    <Section
      sectionA={sectionA}
      sectionB={sectionB}
    />
  );
};

export default SummarySection;
