import { HttpMethod } from '../../../../../../constants';
import * as organizationObject from './OrganizationObject';
import * as vendornObject from './VendorObject';

const version = 'v2';
const baseUrl = 'users';
const docLink = `docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'user_object',
  },
  get: {
    id: 'user_retrieval',
  },
};

const model = {
  header: 'Users',
  title: 'The User Object',
  object: endpointIds.object.id,
  link: `/docs/api/${version}#${endpointIds.object.id}`,
};

const attributes = {
  id: { type: 'string', description: 'The user\'s unique identifier.' },
  bio: { type: 'string', description: 'The user\'s bio.' },
  disability: { type: 'boolean', description: 'True if a user has a disability. False if otherwise.' },
  email: { type: 'boolean', description: 'The user\'s email.' },
  ethnicity: { type: 'string', description: 'The user\'s ethnicity.' },
  gender: { type: 'string', description: 'The user\'s gender.' },
  headshot: { type: 'string', description: 'The user\'s headshot.' },
  isPublicProfile: { type: 'boolean', description: 'True if the user\'s profile is public. False if otherwise.' },
  name: { type: 'string', description: 'The user\'s first name.' },
  surname: { type: 'string', description: 'The user\'s last name.' },
  organizations: {
    type: 'array of objects',
    description: 'An array of the user\'s organization (as an Organization Object) that they are a member/owner of.',
    props: [
      ...Object.keys(organizationObject.attributes).map(att => {
        if (att === 'description') {
          return 'descriptionProp';
        }
        return att;
      }),
    ],
    ...organizationObject.attributes,
  },
  vendors: {
    type: 'array of objects',
    description: 'An  array of the user\'s vendor details for the organization that they are a member/owner of.',
    props: [
      ...Object.keys(vendornObject.attributes).map(att => {
        if (att === 'description') {
          return 'descriptionProp';
        }
        return att;
      }),
    ],
    ...vendornObject.attributes,
  },
};

const endpoints =
{
  get: {
    id: endpointIds.get.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.get.id}`,
    endpoint: `/${baseUrl}?id=`,
    example: `/${baseUrl}?id=a14406ab-9b1b-4b64-ba73-062e8a471b4e`,
    title: 'Retrieve a User',
    description: 'Retrieves a user by the user id',
    returnContent: 'Returns the User object if the call succeeded',
  },
};

export {
  model,
  attributes,
  endpoints,
};
