import { Button, Modal } from 'react-bootstrap';

const DeleteUserModal = ({ show, handleClose, selectedMember, handleDeleteSubmitted }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>
        Delete
        {' '}
        {selectedMember?.userData?.name}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h5>
        Are you sure you want to delete
        {' '}
        {selectedMember?.userData?.name}
        ?
      </h5>
      <h5 className="mt-24 fw-400">You can&apos;t undo this action.</h5>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-dark" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="dark-blue" onClick={handleDeleteSubmitted}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DeleteUserModal;
