import { useState, useEffect, useCallback } from 'react';
import { Card, Container, Row, Col, Badge, Button } from 'react-bootstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { firestore } from '../../providers/FirebaseProvider/firebase';
import convertToDateTime from '../../utils/convertToDateTime';
import { isLoadingSignal, userDataSignal } from '../../libs/signals';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const Dashboard = () => {
  const [stats, setStats] = useState({
    count: {
      success: 0,
      failure: 0,
    },
  });
  const [recentErrors, setRecentErrors] = useState([]);
  const [keysData, setKeysData] = useState([]);

  const fetchAndSetRecentErrors = useCallback(async () => {
    const ref = await firestore
      .collection('stats')
      .doc(userDataSignal?.value?.userData?.clientId)
      .collection('logs')
      .where('result', '==', 'failure')
      .orderBy('createdAt', 'desc')
      .limit(5)
      .get();
    setRecentErrors(ref.docs.map((doc) => doc.data()));
    const fbClientRef = firestore.collection('clients').doc(userDataSignal?.value?.userData?.clientId);
    const clientSnap = await fbClientRef.get();
    const clientObj = clientSnap.data();
    const newKeysArray = [];
    if (clientObj?.clientData) {
      for (let i = 0; i < clientObj.clientData.apiKeys.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const data = await clientObj.clientData.apiKeys[i].get();
        const apiData = data.data();
        newKeysArray.push(apiData);
      }
      setKeysData(newKeysArray);
    }
  });

  useEffect(() => {
    isLoadingSignal.value = true;
    fetchAndSetRecentErrors();
    firestore.collection('stats')
      .doc(userDataSignal?.value?.userData?.clientId)
      .onSnapshot(async (doc) => {
        let successTmp = 0;
        let failureTmp = 0;
        const data = doc.data();
        if (data) {
          data.stats.forEach((apiKey, index) => {
            const apiName = Object.keys(apiKey)[0];
            successTmp += data.stats[index][apiName].success;
            failureTmp += data.stats[index][apiName].failure;
          });
          setStats({
            count: {
              success: successTmp,
              failure: failureTmp,
            },
          });
        }
      });
    isLoadingSignal.value = false;
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
  };

  const apiRequestLabels = ['SUCCESSFUL', 'FAILED'];

  const apiRequestData = {
    labels: apiRequestLabels,
    datasets: [
      {
        label: 'API Requests',
        data: [stats.count.success, stats.count.failure],
        backgroundColor: '#0565AA',
      },
    ],
  };

  const handleViewLogs = () => {
    window.location = 'logs?tab=failed';
  };

  return (
    <Container fluid>
      <Row className="m-16">
        <Col sm={2} className="mt-32">
          <h2 className="text-center fw-700">Developers</h2>
          <Row className="mt-16">
            <Col sm={4} className="pe-0">
              <Card bg="light-grey h-100 ms-auto border-0" style={{ width: '8px' }}>
                <Card bg="dark-blue" className="py-8 border-0" style={{ width: '8px' }} />
              </Card>
            </Col>
            <Col sm={7}>
              <Link className="text-decoration-none text-dark-blue" to="/dashboard"><h5 className="mb-24 fw-700">Overview</h5></Link>
              <Link className="text-decoration-none text-dark" to="/keys"><h5 className="mb-24">API keys</h5></Link>
              <Link className="text-decoration-none text-dark" to="/logs"><h5 className="mb-0">Logs</h5></Link>
            </Col>
          </Row>
        </Col>
        <Col sm={10}>
          <Row className="mt-24">
            <Col sm={8}>
              <Row className="border-bottom">
                <Col sm={12} className="p-0">
                  <h1>Your integration</h1>
                  <p>
                    Need help integrating OpenGrants into your platform,
                    {' '}
                    <a className="fw-bold" href="https://www.opengrants.io/opengrants-professional-services/">click here</a>
                    {' '}
                    to get connected to our development partner
                  </p>
                </Col>
              </Row>
              <Row className="mt-24">
                <Col sm={12} className="p-0">
                  <h3 className="fw-800">API requests</h3>
                  <div className="d-flex justify-content-around">
                    <div>
                      <h5>
                        Successful
                        {' '}
                        {stats.count.success}
                      </h5>
                    </div>
                    <div>
                      <h5>
                        Failed
                        {' '}
                        {stats.count.failure}
                      </h5>
                    </div>
                  </div>
                  <Bar
                    options={options}
                    data={apiRequestData}
                  />
                </Col>
              </Row>
              <Row className="mt-24 d-flex border-bottom">
                <Col sm={8}>
                  <h3 className="fw-700 d-inline">API Versions</h3>
                </Col>
                <Col sm={4}>
                  <Link className="text-decoration-none" to="/keys"><h5 className="text-end mb-0">View Keys</h5></Link>
                </Col>
              </Row>
              {keysData && keysData?.map((version, index) => (
                <Row className="border-bottom py-8" key={version?.key}>
                  <Col sm={3} className="d-flex align-content-center">
                    <h5 className="fw-800 mb-0 d-flex align-items-center text-dark text-uppercase">
                      {version.name}
                    </h5>
                  </Col>
                  <Col sm={3} className="d-flex align-content-center">
                    <h5 className="fw-700 mb-0 d-flex align-items-center text-blue">
                      {convertToDateTime(version?.createdAt, 'dateOnly')}
                    </h5>
                  </Col>
                  <Col sm={6} className="d-flex align-items-center justify-content-end">
                    <Badge bg={index + 1 === keysData.length ? 'dark-blue' : 'dark'} className="my-auto">
                      <h5 className="mb-0">{index + 1 === keysData.length ? 'LATEST' : 'DEFAULT'}</h5>
                    </Badge>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col sm={{ offset: 1, span: 3 }}>
              <Row className="border-bottom">
                <Col>
                  <h1>Recent Errors</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  {recentErrors?.length > 0 ? (
                    recentErrors?.map((errObj, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Card key={index} className="p-8 my-8 fw-700 ">
                        <Row className="p-0">
                          <Col sm={6} className="my-auto">
                            <Card className="border-danger bg-danger text-light text-center">
                              {errObj.status}
                            </Card>
                          </Col>
                          <Col sm={6} className="my-auto">
                            {convertToDateTime(errObj.createdAt)}
                          </Col>
                        </Row>

                      </Card>
                    ))
                  ) : (
                    <p>No errors to report</p>
                  )}
                  <Button
                    variant="outline-dark-blue"
                    size="sm"
                    className="w-100 mt-24 fw-600"
                    onClick={() => handleViewLogs()}
                  >
                    View failed Logs
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
