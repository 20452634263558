const Section = ({ endpoints }) => (
  <div>
    {Object.entries(endpoints).map(([date, info]) => (
      <div key={info.id}>
        <h3>{date}</h3>
        <ul>
          {info.descriptions.map(description => <li>{description}</li>)}
        </ul>
      </div>
    ))}
  </div>
);

export default Section;
