import { Col, Row } from 'react-bootstrap';
import SideNavigation from '../Shared/SideNavigation';
import Section from './Components/Section';
import * as changelogObject from './Objects/ChangelogObject';
import apiReferenceObject from '../Api/v2/Objects/ApiReferenceObject';

const Changelog = () => (
  <Row className="vw-100 vh-100 bg-white">
    <Col sm={3} className="p-48 h-100 overflow-scroll">
      <h2>OpenGrants Changelog</h2>
      <SideNavigation
        objects={[changelogObject]}
      />
      <hr />
      <SideNavigation
        objects={[{ model: apiReferenceObject }]}
      />
    </Col>
    <Col sm={9} className="p-48 h-100 overflow-scroll api-docs">
      <h1>Changelog</h1>
      <p>This changelog lists all additions and updates to the OpenGrants API.</p>
      <hr />
      <Section
        endpoints={changelogObject.endpoints}
      />
    </Col>
  </Row>
);

export default Changelog;
