import { Redirect as RedirectDOM } from 'react-router-dom';
import useIsLoggedIn from '../../utils/useIsLoggedIn';

const Redirect = () => {
  const isLoggedIn = useIsLoggedIn();
  const RedirectUrl = isLoggedIn ? '/dashboard' : '/login';
  return (<RedirectDOM to={RedirectUrl} />);
};

export default Redirect;
