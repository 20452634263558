/* eslint-disable import/prefer-default-export */
export const getConfig = (key) => {
  const base = {
    BASE_URL: 'http://localhost:3000',
    REACT_APP_API_ENDPOINT: 'http://localhost:5002/opengrants-api-portal/us-central1/api',
    REACT_APP_FIREBASE_CONFIG: {
      apiKey: 'AIzaSyCXEeGhDJjsaBBO1ZIjIBqy2x1PJliuT7w',
      authDomain: 'opengrants-api-portal.firebaseapp.com',
      projectId: 'opengrants-api-portal',
      storageBucket: 'opengrants-api-portal.appspot.com',
      messagingSenderId: '611891385208',
      appId: '1:611891385208:web:3c86349e42db863fd12e8d',
      measurementId: 'G-NNWCZXPWPF',
    },
    REACT_APP_LINKEDIN_CLIENT_ID: '184af12f-0a7c-46dd-b1a8-adf550c399b0',
  };

  const development = {
    ...base,
  };

  const staging = {
    ...development,
    BASE_URL: 'https://opengrants-api-portal-staging.web.app',
    REACT_APP_API_ENDPOINT: 'http://localhost:5002/opengrants-api-portal/us-central1/api',
  };

  const feature = {
    ...development,
    BASE_URL: 'https://opengrants-api-portal-feature.web.app',
    REACT_APP_API_ENDPOINT: 'http://localhost:5002/opengrants-api-portal/us-central1/api',
  };

  const qa = {
    ...development,
    BASE_URL: 'https://opengrants-api-portal-qa.web.app',
    REACT_APP_API_ENDPOINT: 'http://localhost:5002/opengrants-api-portal/us-central1/api',
  };

  const production = {
    ...qa,
    BASE_URL: 'https://opengrants-api-portal.web.app',
    REACT_APP_API_ENDPOINT: 'https://us-central1-opengrants-api-portal.cloudfunctions.net/api',
  };

  const configSettings = {
    development,
    feature,
    qa,
    staging,
    production,
  };
  let env = 'production';

  if (typeof process !== 'undefined') {
    env = process?.env?.REACT_APP_ENV || 'production';
  }

  const config = configSettings[env] || configSettings.production;

  if (!key) {
    return config;
  }

  return config[key];
};
