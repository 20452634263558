const generateNavigation = (object) => {
  if (!object) return null;
  return (
    <div key={object.model.header} className="pb-8">
      <li><a href={object.model.link}>{object.model.header}</a></li>
      {(object.model.title || object.endpoints) && (
        <div className="ps-8">
          {object.model.title && <li><a href={object.model.link}>{object.model.title}</a></li>}
          {object.endpoints && Object.entries(object.endpoints).map(([, endpoint]) => (
            <li key={endpoint.title}><a href={endpoint.link}>{endpoint.title}</a></li>
          ))}
        </div>
      )}
    </div>
  );
};

export default generateNavigation;
