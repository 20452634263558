const convertToDateTime = (timestamp, type) => {
  if (type === 'dateOnly') {
    const date = timestamp.toDate();
    const mm = date.getMonth();
    const dd = date.getDate();
    const yyyy = date.getFullYear();
    const formattedDate = `${mm}/${dd}/${yyyy}`;
    return `${formattedDate}`;
  }
  if (type === 'timeOnly') {
    const dateInMillis = timestamp * 1000;
    const timeDate = new Date(dateInMillis);
    const myTime = timeDate.toLocaleTimeString();
    return `${myTime}`;
  }
  const date = timestamp.toDate();
  const mm = date.getMonth();
  const dd = date.getDate();
  const yyyy = date.getFullYear();
  const formattedDate = `${mm}/${dd}/${yyyy}`;

  const dateInMillis = timestamp * 1000;
  const timeDate = new Date(dateInMillis);
  const myTime = timeDate.toLocaleTimeString();
  return `${formattedDate} ${myTime}`;
};

export default convertToDateTime;
