import { signal } from '@preact/signals-react';

const globalSignal = signal();

export const loadingSignal = signal({
  loading: { // OBJ Name === name on signal Map
    isLoading: true,
    loadingMessage: '',
  },
});

export const isLoadingSignal = signal(true);

export const isLoggedInSignal = signal(false);

export const loadingStore = {
  get: () => loadingSignal.value.loading,
  start: (message) => {
    loadingSignal.value.loading.isLoading = true;
    if (!message) return;
    loadingSignal.value.loading.loadingMessage = message;
  },
  end: () => {
    loadingSignal.value.loading.isLoading = false;
    loadingSignal.value.loading.loadingMessage = '';
  },
};

export const errorsSignal = signal({
  errors: { // OBJ Name === name on signal Map
    error: [],
  },
});
export const userDataSignal = signal({
  userData: {
    uid: '',
    email: '',
    creationTime: '',
    lastSignInTime: '',
  },
});
export const clientDataSignal = signal({
  clientData: {
    name: '',
    members: [],
  },
});

const signalMap = {
  loading: loadingSignal,
  errors: errorsSignal,
  userData: userDataSignal,
  clientData: clientDataSignal,
};

export const updateSignal = (newVal, signalKey) => {
  if (signalKey) {
    signalMap[signalKey].value = {
      ...signalMap[signalKey].value,
      ...newVal,
    };
  } else {
    globalSignal.value = {
      ...globalSignal.value,
      ...newVal,
    };
  }
};

export default globalSignal;
