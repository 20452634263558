import ObjectSection from './Components/ObjectSection';
import RequestSection from './Components/RequestSection';

const ApiSection = ({ object, examples }) => (
  <div>
    {object.attributes && (
      <>
        <section id={object.model.object}>
          <ObjectSection
            header={object.model.header}
            title={object.model.title}
            endpoints={object.endpoints}
            model={object.attributes}
            data={examples?.object || {}}
          />
        </section>
        <hr />
      </>
    )}
    {object.endpoints && Object.entries(object.endpoints).map(([key, attributes]) => (
      <div key={key}>
        <section id={attributes.id}>
          <RequestSection
            endpoint={attributes}
            example={examples[key]}
          />
        </section>
        <hr />
      </div>
    ))}
  </div>
);

export default ApiSection;
