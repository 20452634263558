import { HttpMethod } from '../../../../../../constants';
import * as grantObject from './GrantObject';
import * as funderObject from './FunderObject';

const version = 'v2';
const baseUrl = 'matches';
const docLink = `docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'match_object',
  },
  getAll: {
    id: 'match_list',
  },
  generate: {
    id: 'match_generate',
  },
};

const model = {
  header: 'Matches',
  title: 'The Match Object',
  object: endpointIds.object.id,
  link: `/docs/api/${version}#${endpointIds.object.id}`,
};

const attributes = {
  data: {
    type: 'array of objects',
    description: 'An array of matches and their funders and grants',
    props: ['name'],
    name: {
      type: 'object',
      description: 'Name is a unique dynamic name that was retrieved from the post body when generating matches.',
      props: ['grants', 'funders'],
      grants: {
        type: 'object',
        description: 'The grant matches and the totalResults.',
        props: ['data', 'totalResults'],
        data: {
          props: [
            ...Object.keys(grantObject.attributes).map(att => {
              if (att === 'description') {
                return 'descriptionProp';
              }
              return att;
            }),
            'matchingPercentage',
          ],
          ...grantObject.attributes,
          descriptionProp: grantObject.attributes.description,
          matchingPercentage: {
            type: 'float',
            description: 'The matching percentage between the grants and the description from the post body for the unique name.',
          },
          type: 'array of objects',
          description: 'An array of matching Grant objects in comparison to the description specified in the post body from when a request was made to generate matches.',
        },
        totalResults: {
          type: 'int',
          description: 'The total number of matching grants found',
        },
      },
      funders: {
        type: 'object',
        description: 'The funder matches and the totalResults.',
        props: ['data', 'totalResults'],
        data: {
          props: [
            ...Object.keys(funderObject.attributes).map(att => {
              if (att === 'description') {
                return 'descriptionProp';
              }
              return att;
            }),
            'matchingPercentage',
          ],
          ...funderObject.attributes,
          descriptionProp: funderObject.attributes.description,
          matchingPercentage: {
            type: 'float',
            description: 'The matching percentage between the funders and the description from the post body for the unique name.',
          },
          type: 'array of objects',
          description: 'An array of matching Funder objects in comparison to the description specified in the post body from when a request was made to generate matches.',
        },
        totalResults: {
          type: 'int',
          description: 'The total number of matching grants found',
        },
      },
    },
  },
};

const endpoints = {
  getAll: {
    id: endpointIds.getAll.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.getAll.id}`,
    endpoint: `/${baseUrl}`,
    example: `/${baseUrl}`,
    title: 'Retrieve all Matches',
    description: 'Retrieve all matches from when a request was made to generate matches for all time.',
    returnContent: 'Returns grant and funder matches for all the unique names in the post body from when a request was made to generate matches for all time.',
  },
  generate: {
    id: endpointIds.generate.id,
    method: HttpMethod.POST,
    link: `/${docLink}#${endpointIds.generate.id}`,
    endpoint: `/${baseUrl}`,
    example: `/${baseUrl}`,
    examplePostBody: {
      data: [
        {
          Name: 'OG',
          Description: 'We leverage the latest tools from OpenGrants and exceptional training to deliver incredible results in a cost effective and efficient way.',
          State: 'CA',
        },
        {
          Name: 'OG Research',
          Description: 'Egeria Research is revolutionizing the way that grant discovery and grant funding strategy work.',
        },
      ],
    },
    title: 'Generate Matches',
    description: 'Generate grant and funder matches for the given post body. This takes a minimum of 30 seconds. The bigger the list, longer the response time.',
    returnContent: 'Returns grant and funder matches for all the unique names in the post body.',
    bodyParameters: {
      name: { type: 'required', description: 'The unique name to use for identiying the grant and funder matches.' },
      description: { type: 'required', description: 'The description to use for grant and funder matching.' },
      state: { type: 'optional', description: 'Match grants and funders by the specified state. Possible values are \'Federal\', \'International\', and any states from the United States' },
    },
  },
};

export {
  model,
  attributes,
  endpoints,
};
