import * as grantObject from '../../Api/v2/Objects/GrantObject';
import * as organizationObject from '../../Api/v2/Objects/OrganizationObject';

const docLink = '/docs/changelog';

const endpointIds = {
  object: {
    id: 'changelog',
  },
  'August 3, 2024': {
    id: 'aug32024',
  },
  'July 22, 2024': {
    id: 'jul222024',
  },
  'March 10, 2024': {
    id: 'mar102024',
  },
  'Febraury 22, 2024': {
    id: 'feb222024',
  },
  'Febraury 2, 2024': {
    id: 'feb22024',
  },
  'January 22, 2024': {
    id: 'jan222024',
  },
  'January 11, 2024': {
    id: 'jan112024',
  },
  'December 11, 2024': {
    id: 'dec112024',
  },
};

const model =
{
  header: 'Changelog',
  object: endpointIds.object.id,
  link: `${docLink}#${endpointIds.object.id}`,
};

const endpoints = {
  'August 3, 2024': {
    title: 'August 3, 2024',
    id: endpointIds['August 3, 2024'].id,
    link: `${docLink}#${endpointIds['August 3, 2024'].id}`,
    descriptions: [
      <>
        Deprecated &quot;filterFacets&quot; in favor of &quot;filters,&quot; which
        now allows filtering by numeric comparison when
        <span> </span>
        <a href={grantObject.endpoints.search.link}>searching grants</a>
        .
      </>,
    ],
  },
  'July 22, 2024': {
    title: 'July 22, 2024',
    id: endpointIds['July 22, 2024'].id,
    link: `${docLink}#${endpointIds['July 22, 2024'].id}`,
    descriptions: [
      <>
        Add filtering for eligible activities when
        <span> </span>
        <a href={grantObject.endpoints.search.link}>searching grants</a>
      </>,
    ],
  },
  'March 10, 2024': {
    title: 'March 10, 2024',
    id: endpointIds['March 10, 2024'].id,
    link: `${docLink}#${endpointIds['March 10, 2024'].id}`,
    descriptions: [
      <>
        Add new endpoint for retrieving and searching
        <span> </span>
        <a href={organizationObject.model.link}>grant seeking organizations</a>
      </>,
    ],
  },
  'Febraury 22, 2024': {
    title: 'Febraury 22, 2024',
    id: endpointIds['Febraury 22, 2024'].id,
    link: `${docLink}#${endpointIds['Febraury 22, 2024'].id}`,
    descriptions: [
      'Include a matching percentage in the match object for both grants and funders, indicating the extent to which the post body aligns with a specific grant or funder - matchingPercentage.',
      'Instead of providing a consolidated list of the top 20 - 30 matches for both grants and funders, return separate lists for each, showcasing their respective top 20-30 matches.',
    ],
  },
  'Febraury 2, 2024': {
    title: 'Febraury 2, 2024',
    id: endpointIds['Febraury 2, 2024'].id,
    link: `${docLink}#${endpointIds['Febraury 2, 2024'].id}`,
    descriptions: [
      'Add new filterable category for grants - otherCategory.',
    ],
  },
  'January 22, 2024': {
    title: 'January 22, 2024',
    id: endpointIds['January 22, 2024'].id,
    link: `${docLink}#${endpointIds['January 22, 2024'].id}`,
    descriptions: [
      'Add support for searching and retrieving only state grants. By default, ["locations:{state}"] on its own, returns state and federal grants that specify the state. To exclusively search and retrieve state grants, use an add operation "inlcudeFederal:false" with a locations facet filter as shown in the example: ["locations:{state}","includeFederal:false"].',
    ],
  },
  'January 11, 2024': {
    title: 'January 11, 2024',
    id: endpointIds['January 11, 2024'].id,
    link: `${docLink}#${endpointIds['January 11, 2024'].id}`,
    descriptions: [
      'Add support for filtering grants by match required - matchRequired.',
    ],
  },
  'December 11, 2024': {
    title: 'December 11, 2024',
    id: endpointIds['December 11, 2024'].id,
    link: `${docLink}#${endpointIds['December 11, 2024'].id}`,
    descriptions: [
      'Add a new api endpoint.',
      'Deprecated version 1 of the api.',
    ],
  },
};

export {
  model,
  endpoints,
};
