import { Badge } from 'react-bootstrap';

const StatusPill = ({ status }) => {
  if (status === 401) {
    return (
      <Badge bg="info" className="p-8 w-100 d-flex aligns-items-center justify-content-center">
        401 ERR
      </Badge>
    );
  }
  if (status === (500)) {
    return (
      <Badge bg="danger" className="p-8 w-100 d-flex aligns-items-center justify-content-center">
        500 ERR
      </Badge>
    );
  }
  return (
    <Badge bg="dark" className="p-8 w-100 d-flex aligns-items-center justify-content-center">
      200 OK
    </Badge>
  );
};

export default StatusPill;
