const version = 'v2';
const docLink = `/docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'api_reference',
  },
};

const model = {
  header: 'Api Reference',
  link: `${docLink}#${endpointIds.object.id}`,
};

export default model;
