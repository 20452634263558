import { HttpMethod } from '../../../../../../../constants';

const version = 'v1';
const baseUrl = 'funders';
const docLink = `docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'funder_object',
  },
  get: {
    id: 'funder_retrieval',
  },
  getAll: {
    id: 'funder_list',
  },
  search: {
    id: 'funder_search',
  },
};

const model = {
  header: 'Funders',
  title: 'The Funder Object',
  object: endpointIds.object.id,
  link: `/docs/api/v2#${endpointIds.object.id}`,
};

const attributes = {
  id: { type: 'int', description: 'The funder\'s unique identifier.' },
  name: { type: 'string', description: 'The funder\'s name' },
  description: { type: 'string', description: 'The funder\'s description' },
  locations: {
    type: 'array of strings',
    description:
      (
        <>
          {'The grant\'s applicable locations. Some possbile values are Federal, International, and states in the united states.'}
          <span> </span>
          <a href={`/docs/api/${version}#filter_object`}>
            See Filter Options to retrieve all possible options.
          </a>
        </>
      ),
  },
  website: { type: 'string', description: 'The funder\'s website' },
};

const endpoints = {
  get: {
    id: endpointIds.get.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.get.id}`,
    endpoint: `/${baseUrl}/{id}`,
    example: `/${baseUrl}/8523`,
    title: 'Retrieve a Funder',
    description: 'Retrieve a funder by the funder id',
    returnContent: 'Returns the Funder object if the call succeeded',
  },
};

export {
  model,
  attributes,
  endpoints,
};
