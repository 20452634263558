const model = {
  header: 'Organization',
  title: 'The Organization Object',
  object: 'organization_object',
  link: '/docs/api/v2#organization_object',
};

const attributes = {
  id: { type: 'int', description: 'The organization\'s unique id.' },
  address: { type: 'string', description: 'The organization\'s street address.' },
  businiess8aDevelopment: { type: 'boolean', description: 'True if the organization is a business 8a development. False if otherwise.' },
  city: { type: 'string', description: 'The city the organization is located at.' },
  contactEmail: { type: 'string', description: 'The organization\'s point of contact' },
  country: { type: 'string', description: 'The country the organization is located at.' },
  descriptionProp: { type: 'string', description: 'A short description about the organization.' },
  uniqueEntityIdentifier: { type: 'string', description: 'The organization\'s unique entity identifier, previously known as the DUNS number.' },
  hubZoneOwned: { type: 'boolean', description: 'True if the organization is hubzone owned. False otherwise.' },
  industries: { type: 'array of strings', description: 'An array of industries the organization is associated with.' },
  logoURL: { type: 'string', description: 'The url to organization\'s logo.' },
  name: { type: 'string', description: 'The organization\'s name.' },
  naicsCodes: { type: 'array of strings', description: 'An array of naics codes that the organization is associated with.' },
  orgType: { type: 'string', description: 'The organization\'s type. Possible types are: \'Individual/Sole Proprietor\', \'Partnership\', \'LLC\', \'Corporation\', \'Nonprofit\', \'State Government\', \'Local Government\', \'Federal Government\', \'Tribal Government\', \'Other\'' },
  phoneNumber: { type: 'string', description: 'The organization\'s phone number.' },
  samNumber: { type: 'string', description: 'The organizations\'s SAM number.' },
  small_Business: { type: 'boolean', description: 'True if the organization is a small business. False if otherwise.' },
  socialImpactCategories: {
    category: { type: 'array of string', description: 'An array of categories that the organization is associated with.' },
  },
  state: { type: 'string', description: 'The state the organization is located at.' },
  technologiesUsed: {
    technology: { type: 'array of string', description: 'An array of technologies that the organization is associated with.' },
  },
  womanOwned: { type: 'boolean', description: 'True if the organization is woman owned. False if otherwise.' },
  veteranOwned: { type: 'boolean', description: 'True if the organization is veteran owned. False if otherwise.' },
  websiteURL: { type: 'string', description: 'The organization\'s website.' },
  zipCode: { type: 'string', description: 'The zipcode the organization is located at.' },
};

const examples = [
  {
    organizationId: 2269,
    name: 'Egeria Research',
    address: '705 Gold Lake Drive',
    city: 'Folsom',
    country: 'United States',
    phoneNumber: '7077013419',
    contactEmail: null,
    description: 'Egeria Research is revolutionizing the way that grant discovery and grant funding strategy work takes place. We leverage the latest tools from OpenGrants and exceptional training to deliver incredible results in a cost effective and efficient way. \n\nOur team has deep experience in California, Florida and New York. With over ten years of experience managing strategy for everyone from startups to Fortune 500 companies, we are here to give you deep insights into Federal, State and Local government grants as well as foundations and corporate grant funding opportunities.\n\nOur team focuses on funding strategy work at the onset of every engagement. A funding strategy is a roadmap of grant opportunities. We will help you plan the top 2-5 grants your organization will pursue over the next 24 months, and provide analysis on next steps, timelines, points of contact at target funders and probability of success.\n',
    businiess8aDevelopment: false,
    womanOwned: false,
    veteranOwned: false,
    hubZoneOwned: false,
    samNumber: false,
    state: 'California',
    uniqueEntityIdentifier: null,
    smallBusiness: true,
    zipCode: '95630',
    industries: [],
    technologiesUsed: [],
    socialImpactCategories: [],
    naicsCodes: null,
    websiteUrl: 'https://egeriaresearch.org/',
    logoURL: 'https://egeria-profiles.s3-us-west-1.amazonaws.com/organization_profile/2269/2269.jpg?a7701e36-9b36-4b97-afbb-c419ddbf5f73',
    orgType: 'Nonprofit',
  },
];

export {
  model,
  attributes,
  examples,
};
