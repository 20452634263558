import { useLayoutEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import PublicRoute from './components/global/Routes/PublicRoute';
import PrivateRoute from './components/global/Routes/PrivateRoute';
import Login from './components/views/Login/Login';
import Dashboard from './components/views/Dashboard';
import Keys from './components/views/Keys';
import DocV1 from './components/views/Docs/Api/v1';
import DocV2 from './components/views/Docs/Api/v2';
import { auth } from './components/providers/FirebaseProvider/firebase';
import { isLoggedInSignal } from './components/libs/signals';
import { subscribe as fetchUserData } from './components/providers/FirebaseProvider/fbSubs';
import ResetPassword from './components/views/ResetPassword';
import Logs from './components/views/Logs';
import Users from './components/views/Users';
import Redirect from './components/global/Redirect';
import CreateUser from './components/views/CreateUser';
import AlertProvider from './components/providers/AlertProvider/AlertProvider';
import Clients from './components/views/Clients';
import OGRoute from './components/global/Routes/OGRoute';
import Changelog from './components/views/Docs/Changelog/Doc';

const App = () => {
  useLayoutEffect(() => {
    let dispatchSubscriber = () => { };
    const dispatchAuthChange = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        window.localStorage.setItem('loggedIn', true);
        isLoggedInSignal.value = true;
      } else {
        window.localStorage.setItem('loggedIn', false);
        isLoggedInSignal.value = false;
      }
      dispatchSubscriber = userAuth && await fetchUserData(userAuth);
    });
    return () => {
      dispatchAuthChange();
      dispatchSubscriber();
    };
  }, []);

  return (
    <AlertProvider>
      <Router>
        <Switch>
          <PublicRoute path="/" exact component={Redirect} />
          <PublicRoute path="/login" exact component={Login} />
          <PublicRoute path="/reset" exact component={ResetPassword} />
          <PublicRoute path="/create" exact component={CreateUser} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/keys" component={Keys} />
          <PrivateRoute path="/logs" component={Logs} />
          <PrivateRoute path="/users" component={Users} />
          <OGRoute path="/clients" component={Clients} />

          {/* API DOCS */}
          <PublicRoute path="/docs/api/v1" exact component={DocV1} />
          <PublicRoute path="/docs/api/v2" exact component={DocV2} />

          {/* CHANGELOG DOC */}
          <PublicRoute path="/docs/changelog" exact component={Changelog} />
        </Switch>
      </Router>
    </AlertProvider>
  );
};

export default App;
