import { Col, Row } from 'react-bootstrap';

const Section = ({ sectionA, sectionB }) => (
  <Row>
    <Col sm={7}>
      {sectionA}
    </Col>
    <Col sm={5}>
      {sectionB}
    </Col>
  </Row>
);

export default Section;
