import { useState } from 'react';

const Attributes = ({ model }) => {
  const [showObjects, setShowObjects] = useState({});
  const modelIndexCount = Object.keys(model).length - 1;
  const [showProps, setShowProps] = useState(false);

  return (
    Object.entries(model)?.map(([attribute, information], modelIdx) => {
      const propsCount = information.props ? information.props.length - 1 : 0;
      return (
        <>
          <div>
            <h5>
              {attribute}
              <span> </span>
              <span className="text-grey">
                {information.type}
                {information.isDeprecated ? ' **DEPRECATED** ' : ''}
              </span>
              {information.isDeprecated && (
              <button
                type="button"
                onClick={() => setShowProps(prev => !prev)}
              >
                {(!showProps ? 'Show ' : 'Hide ')}
                Description
              </button>
              )}
            </h5>
            {(!information.isDeprecated || showProps) &&
              <p>{information.description}</p>}
            {((information.props && information.isDeprecated && showProps) ||
            (information.props && !information.isDeprecated)) && (
              <div>
                {!showObjects[attribute] && <button type="button" onClick={() => setShowObjects({ ...showObjects, [attribute]: true })}>+ Show object</button>}
                {showObjects[attribute] && <button type="button" className="w-100 text-right" onClick={() => setShowObjects({ ...showObjects, [attribute]: false })}>- Hide object</button>}
                {showObjects[attribute] && (
                  <div className="border p-8 rounded">
                    {information.props.map((prop, propsIdx) => (
                      <>
                        <Attributes
                          model={{ [prop.split('Prop')[0]]: information[prop] }}
                        />
                        {propsIdx < propsCount && <hr />}
                      </>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          {modelIdx < modelIndexCount && <hr />}
        </>
      );
    })
  );
};

export default Attributes;
