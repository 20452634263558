/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Card, Container, Image, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import lock from '../../../images/lock.svg';
import Microsoft from '../../../images/Microsoft.svg';
import Google from '../../../images/google.svg';
import loginBackground from '../../../images/loginBackground.svg';
// import AppContainer from '../../global/AppContainer';
import { auth, firestore, googleProvider, microsoftProvider } from '../../providers/FirebaseProvider/firebase';
import { useAlert } from '../../providers/AlertProvider/AlertProvider';
import { isLoadingSignal } from '../../libs/signals';

const Login = () => {
  const setAlert = useAlert();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleUserLoginEmail = async () => {
    try {
      isLoadingSignal.value = true;
      const result = await auth.signInWithEmailAndPassword(email, password);
      if (result) {
        await firestore.collection('users').doc(email).get().then(async (res) => {
          if (!res.exists) {
            isLoadingSignal.value = false;
            setAlert({
              message: 'This user doesnt exist',
            });
          } else {
            const userObj = res.data();
            await firestore.collection('users').doc(email).update({
              userData: {
                ...userObj?.userData,
                lastSignInTime: new Date(),
              },
            });
            await firestore.collection('users').doc(email).get().then(async (user) => {
              if (user) {
                window.location.href = '/dashboard';
              }
            });
          }
        });
      } else {
        isLoadingSignal.value = false;
        setAlert({
          message: 'Username or password was incorrect.',
        });
      }
    } catch (err) {
      isLoadingSignal.value = false;
      console.error(err);
    }
  };
  const handleUserLoginGoogle = async () => {
    try {
      await auth.signInWithPopup(googleProvider).then(async (result) => {
        isLoadingSignal.value = true;
        const googleEmail = result?.user?.email;
        if (googleEmail) {
          await firestore.collection('users').doc(googleEmail).get().then(async (res) => {
            if (!res.exists) {
              window.localStorage.setItem('loggedIn', false);
              auth.currentUser.delete();
              window.location.href = '/login';
              isLoadingSignal.value = false;
            } else {
              const userObj = res.data();
              await firestore.collection('users').doc(googleEmail).update({
                userData: {
                  ...userObj?.userData,
                  lastSignInTime: new Date(),
                },
              });
              await firestore.collection('users').doc(googleEmail).get().then(async (user) => {
                if (user) {
                  window.location.href = '/dashboard';
                }
              });
            }
          });
        }
      });
    } catch (err) {
      isLoadingSignal.value = false;
      console.error(err);
    }
  };

  const handleUserLoginMicrosoft = async () => {
    try {
      await auth.signInWithPopup(microsoftProvider).then(async (result) => {
        isLoadingSignal.value = true;
        const microsoftEmail = result.additionalUserInfo.profile.userPrincipalName.toLowerCase();
        if (microsoftEmail) {
          await firestore.collection('users').doc(microsoftEmail).get().then(async (res) => {
            if (!res.exists) {
              window.localStorage.setItem('loggedIn', false);
              auth.currentUser.delete();
              window.location.href = '/login';
              isLoadingSignal.value = false;
            } else {
              const userObj = res.data();
              await firestore.collection('users').doc(microsoftEmail).update({
                userData: {
                  ...userObj?.userData,
                  lastSignInTime: new Date(),
                },
              });
              await firestore.collection('users').doc(microsoftEmail).get().then(async (user) => {
                if (user) {
                  window.location.href = '/dashboard';
                }
              });
            }
          });
        }
      });
    } catch (err) {
      isLoadingSignal.value = false;
      console.error(err);
    }
  };
  return (
    <>
      <Image src={loginBackground} alt="login Background" className="position-absolute bottom-0 end-0" />
      <Container className="vh-100 d-flex align-items-center justify-content-center ">
        <Card className="shadow-lg bg-body rounded py-xl-64 px-xl-112 py-lg-40 px-lg-64 py-md-24 px-md-40 w-50">
          <h2 className="fw-700 text-center mx-8">Welcome to the OpenGrants Developers Portal</h2>
          <Form.Group className="form-group">
            <Form.Label htmlFor="inputEmail">Email Address</Form.Label>
            <Form.Control
              className="form-control form-control-sm"
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Label htmlFor="inputEmail" className="mt-16">
              Password
              <Link to="/reset" className="position-absolute end-0 me-112 text-decoration-none">Forgot your password?</Link>
            </Form.Label>
            <Form.Control
              className="form-control form-control-sm mb-8"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Check
              className="py-8 pe-8 d-inline"
              type="checkBox"
              value={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <Form.Label htmlFor="inputStaySignedIn">Stay signed in for a week</Form.Label>
            <p>
              By clicking &quot;Login!&quot; you agree to the
              {' '}
              <Link onClick={() => window.open('https://www.opengrants.io/opengrants-legal/')}>&nbsp;privacy policy</Link>
              {' '}
              and
              {' '}
              <Link onClick={() => window.open('https://www.opengrants.io/opengrants-legal/')}>&nbsp;terms and service</Link>
            </p>
            <p>
              Using The WordPress Plugin?
              {' '}
              <Link onClick={() => window.open('https://billing.stripe.com/p/login/aEUaG61ZubPSdr2aEE')}>Manage Your Billing Here</Link>
            </p>
            <Button
              className="d-flex w-100 justify-content-center fw-700 py-8 mt-16"
              type="submit"
              variant="dark-blue"
              disabled={!email || !password}
              onClick={() => handleUserLoginEmail()}
            >
              <Image src={lock} className="me-8" style={{ padding: '1px' }} />
              Sign In
            </Button>
            <div className="border border-1 mx-40 my-16 border-primary" />
            <Form.Label htmlFor="inputEmail" className="d-flex w-100 justify-content-center">Or sign in with</Form.Label>
            <Button
              className="d-flex w-100 justify-content-center fw-700 py-8 mt-16"
              type="submit"
              variant="light"
              onClick={() => handleUserLoginGoogle()}
            >
              <Image src={Google} className="me-8" style={{ width: '22px' }} />
              Google
            </Button>
            <Button
              className="d-flex w-100 justify-content-center fw-700 py-8 mt-16"
              type="submit"
              variant="light"
              onClick={() => handleUserLoginMicrosoft()}
            >
              <Image src={Microsoft} className="me-8" style={{ padding: '3px' }} />
              Microsoft
            </Button>
          </Form.Group>
        </Card>
      </Container>
    </ >
  );
};

export default Login;
