import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useEffect, useCallback, useState } from 'react';
import { firestore } from '../../providers/FirebaseProvider/firebase';
import { isLoadingSignal, userDataSignal } from '../../libs/signals';
import makeid from '../../utils/makeId';
import convertToDateTime from '../../utils/convertToDateTime';
import { useAlert } from '../../providers/AlertProvider/AlertProvider';

const Keys = () => {
  const setAlert = useAlert();
  const [keysData, setKeysData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [name, setName] = useState('');
  const [selectedKey, setSelectedKey] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const fetchClientData = useCallback(async () => {
    const fbClientRef = firestore.collection('clients').doc(userDataSignal?.value?.userData?.clientId);
    const clientSnap = await fbClientRef.get();
    const clientObj = clientSnap.data();
    const apiKeyArrayArray = [];
    if (clientObj?.clientData) {
      for (let i = 0; i < clientObj.clientData.apiKeys.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const data = await clientObj.clientData.apiKeys[i].get();
        const apiData = data.data();
        apiKeyArrayArray.push(apiData);
      }
      setKeysData(apiKeyArrayArray);
      setFullData(clientObj.clientData);
    }
  });

  useEffect(() => {
    isLoadingSignal.value = true;
    fetchClientData();
    isLoadingSignal.value = false;
  }, []);

  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const handleCreateClicked = () => {
    setIsCreateOpen(!isCreateOpen);
  };
  const handleCreateSubmitted = async () => {
    isLoadingSignal.value = true;
    const key = makeid(20);
    setIsCreateOpen(!isCreateOpen);
    const keysArray = fullData.apiKeys;
    const newObj = {
      name,
      key,
      lastUsed: new Date(),
      createdAt: new Date(),
      clientId: userDataSignal?.value?.userData?.clientId,
      accessType: 'super',
    };
    await firestore.collection('apiKeys').doc(key).set({
      ...newObj,
    });
    const statsRes = (await firestore.collection('stats').doc(`${userDataSignal?.value?.userData?.clientId}`).get()).data();
    const { stats } = statsRes;
    stats.push({
      [key.valueOf()]: {
        failure: 0,
        success: 0,
      },
    });
    await firestore.collection('stats').doc(`${userDataSignal?.value?.userData?.clientId}`).set({
      stats,
    });

    keysArray.push(firestore.collection('apiKeys').doc(key));
    await firestore.collection('clients').doc(userDataSignal?.value?.userData?.clientId).update({
      clientData: {
        ...fullData,
        apiKeys: keysArray,
      },
    });
    handleCreateClicked();
    await fetchClientData();
    isLoadingSignal.value = false;
    setAlert({
      message: 'Key was successfully Added',
      variant: 'success',
    });
  };

  const handleDeleteClicked = (value) => {
    setSelectedKey(value || null);
    setIsDeleteOpen(!isDeleteOpen);
  };
  const handleDeleteSubmitted = async () => {
    isLoadingSignal.value = true;
    const keyPositionInArray = keysData.findIndex(
      key => key === firestore.collection('apiKeys').doc(selectedKey.key),
    );
    const keysArray = fullData.apiKeys;
    keysArray.splice(keyPositionInArray, 1);
    firestore.collection('apiKeys').doc(selectedKey.key).delete();
    const statData = (
      await firestore
        .collection('stats')
        .doc(`${userDataSignal?.value?.userData?.clientId}`)
        .get()
    ).data();
    const statDataTmp = { ...statData };
    const statPositionInArray = statDataTmp.stats.findIndex(
      key => Object.keys(key)[0] === selectedKey.key,
    );
    statDataTmp.stats.splice(statPositionInArray, 1);
    await firestore
      .collection('stats')
      .doc(`${userDataSignal?.value?.userData?.clientId}`)
      .set(statDataTmp);
    firestore.collection('clients').doc(userDataSignal?.value?.userData?.clientId).update({
      clientData: {
        ...fullData,
        apiKeys: keysArray,
      },
    });
    handleDeleteClicked();
    await fetchClientData();
    isLoadingSignal.value = false;
    setAlert({
      message: 'Key was successfully deleted',
      variant: 'success',
    });
  };

  const handleCopyClicked = (value) => {
    navigator.clipboard.writeText(value);
    setAlert({
      message: 'Key was copied to your clipboard',
      variant: 'success',
    });
  };

  return (
    <Container fluid>
      <Row className="m-16">
        <Col sm={2} className="mt-32">
          <h2 className="text-center fw-700">Developers</h2>
          <Row className="mt-16">
            <Col sm={4} className="pe-0">
              <Card bg="light-grey h-100 ms-auto border-0" style={{ width: '8px' }}>
                <Card bg="dark-blue" className="py-8 mt-48 border-0" style={{ width: '8px' }} />
              </Card>
            </Col>
            <Col sm={7}>
              <Link className="text-decoration-none text-dark" to="/dashboard"><h5 className="mb-24">Overview</h5></Link>
              <Link className="text-decoration-none text-dark-blue" to="/keys"><h4 className="mb-24 fw-700">API keys</h4></Link>
              <Link className="text-decoration-none text-dark" to="/logs"><h5 className="mb-0">Logs</h5></Link>
            </Col>
          </Row>
        </Col>
        <Col sm={10}>
          <Card className="mt-24 p-16 pb-0">
            <Row className="border-bottom border-light-dark pb-8">
              <Col sm={8}>
                <h2 className="mb-0 fw-700 me-auto">
                  API keys
                </h2>
                <h5 className="mb-0 py-8">
                  These keys will allow you to authenticate API requests.
                  {/* <Link to="/todo" className="text-decoration-none ms-8">
                    Learn more
                  </Link> */}
                </h5>

              </Col>
              <Col sm={4} className="d-flex align-items-center">
                <Button
                  className="ms-auto fw-700 px-24"
                  variant="outline-dark"
                  onClick={() => handleCreateClicked()}
                >
                  + Create secret key
                </Button>
              </Col>
            </Row>
            <Row className="border-bottom border-light-dark  p-0 pb-8 mt-16 text-start">
              <Col sm={2}>
                NAME
              </Col>
              <Col sm={4}>
                KEY
              </Col>
              <Col sm={2}>
                LAST USED
              </Col>
              <Col sm={2}>
                CREATED
              </Col>
            </Row>
            {keysData && keysData.map((obj) => (
              <Row className="border-bottom border-light-dark p-0 text-start" key={obj?.key}>
                <Col sm={2} className="py-8  d-flex align-items-center ">
                  {obj?.name}
                </Col>
                <Col sm={4} className="py-8 d-flex align-items-center">
                  <Button
                    onClick={() => handleCopyClicked(obj.key)}
                    variant="outline-primary"
                  >
                    Copy Key
                  </Button>
                </Col>
                <Col sm={2} className="py-8  d-flex align-items-center">
                  {convertToDateTime(obj.lastUsed)}
                </Col>
                <Col sm={2} className="py-8 d-flex align-items-center">
                  {convertToDateTime(obj.createdAt)}
                </Col>
                <Col sm={1} className="mx-auto d-flex align-items-center">
                  <Button
                    variant="outline-none"
                    className="m-0 p-0 px-8 my-auto fw-700 w-100"
                    onClick={() => handleDeleteClicked(obj)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Col>
              </Row>
            ))}
          </Card>
        </Col>
      </Row>
      {/* Delete */}
      <Modal show={isDeleteOpen} onHide={handleDeleteClicked}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete
            {' '}
            {selectedKey?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Are you sure you want to delete
            {' '}
            {selectedKey?.name}
            ?
          </h5>
          <h5 className="mt-24 fw-400">You can&apos;t undo this action.</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleDeleteClicked}>
            Cancel
          </Button>
          <Button variant="dark-blue" onClick={handleDeleteSubmitted}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {/* CREATE */}
      <Modal show={isCreateOpen} onHide={handleCreateClicked}>
        <Modal.Header closeButton>
          <Modal.Title>
            Create API Key
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="form-group">
            <h5 className="mb-24 fw-400">Choose a Name for this API Key</h5>
            <Form.Label htmlFor="inputName">Name</Form.Label>
            <Form.Control
              className="form-control form-control-sm"
              type="text"
              name="name"
              value={name}
              onChange={onNameChange}
            />

          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleCreateClicked}>
            Cancel
          </Button>
          <Button variant="dark-blue" onClick={handleCreateSubmitted} disabled={!name}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Keys;
