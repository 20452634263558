/* eslint-disable react/jsx-props-no-spreading */
import { Route } from 'react-router-dom';
import AppContainer from '../../AppContainer';

const PublicRoute = ({ component: Component, restricted, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AppContainer>
        <Component {...props} />
      </AppContainer>
    )}
  />
);

export default PublicRoute;
