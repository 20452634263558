import { HttpMethod } from '../../../../../../../constants';

const version = 'v1';
const baseUrl = 'grants';
const docLink = `docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'grant_object',
  },
  get: {
    id: 'grant_retrieval',
  },
  getAll: {
    id: 'grant_list',
  },
  search: {
    id: 'grant_search',
  },
};

const model = {
  header: 'Grants',
  title: 'The Grant Object',
  object: endpointIds.object.id,
  link: `/docs/api/${version}#${endpointIds.object.id}`,
};

const attributes = {
  id: { type: 'int', description: 'The grant\'s unique identifier.' },
  agency: { type: 'string', description: 'The agency that issued the grant.' },
  applicationDueDate: { type: 'string', description: 'The date the application is due.' },
  contactDetails: { type: 'string', description: 'Details about the contact for the grant.' },
  contactEmail: { type: 'string', description: 'The email to contact about the grant.' },
  description: { type: 'string', description: 'The grant\'s description.' },
  fiscalYear: { type: 'string', description: 'The fiscal year.' },
  fundingAmount: { type: 'string', description: 'The max amount of program funding provided to an eligible applicant in the Program Year. A \'0\' or \'null\' indicates that no funding was found or provided.' },
  listingUrl: { type: 'string', description: 'The link to the grant.' },
  locations: {
    type: 'array of strings',
    description:
      (
        <>
          {'The grant\'s applicable locations. Some possbile values are Federal, International, and states in the united states.'}
          <span> </span>
          <a href={`/docs/api/${version}#filter_object`}>
            See Filter Options to retrieve all possible options.
          </a>
        </>
      ),
  },
  title: { type: 'string', description: 'The grant\'s title.' },
};

const endpoints =
{
  get: {
    id: endpointIds.get.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.get.id}`,
    endpoint: `/${baseUrl}/{id}`,
    example: `/${baseUrl}/1914979`,
    title: 'Retrieve a Grant',
    description: 'Retrieves a grant by the grant object id',
    returnContent: 'Returns the Grant object if the call succeeded',
  },
  getAll: {
    id: endpointIds.getAll.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.getAll.id}`,
    endpoint: `/${baseUrl}/all`,
    example: `/${baseUrl}/all`,
    title: 'Retrieve All Grants',
    description: 'Retrieve all the grants from the OpenGrants database',
    returnContent: 'Returns an array of Grant objects and a totalResult property containing the total count of the data.',
  },
  search: {
    id: endpointIds.search.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.search.id}`,
    endpoint: `/${baseUrl}`,
    example: `/${baseUrl}?query=Health%20Program`,
    title: 'Search Grants',
    description: 'Searches and returns an array of grants from the OpenGrants database via pagination',
    returnContent: 'Returns a Pagination object with the data property containing an array of Grant and Funder objects.',
    queryParameters: {
      query: { type: 'optional', description: 'Search the grant title and description for any mention of the query text.' },
      page: { type: 'optional', description: 'Retrieve the grants from the specifed page.' },
    },
  },
};

export {
  model,
  attributes,
  endpoints,
};
