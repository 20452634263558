const SideNavigation = ({ objects }) => (
  <ul className="p-0 list-unstyled">
    <div className="pb-8">
      <li><a href="/docs/api/v1#api_reference">API Reference</a></li>
    </div>
    {objects?.map(({ object }) => (
      <div className="pb-8">
        <li><a href={object.model.link}>{object.model.header}</a></li>
        <div className="ps-8">
          <li><a href={object.model.link}>{object.model.title}</a></li>
          {object.endpoints && Object.entries(object.endpoints).map(([, endpoint]) => (
            <li><a href={endpoint.link}>{endpoint.title}</a></li>
          ))}
        </div>
      </div>
    ))}
  </ul>
);

export default SideNavigation;
