import Section from '../../../Components/Section';
import { displayJson, getHttpMethodColor } from '../../../../../../../utils/apiDocHelper';
import Attributes from '../../Components/Attributes';
import RequestExample from '../../Components/RequestExample';

const ObjectSection = ({ header, title = 'ENDPOINTS', model, data, endpoints }) => {
  const requestContent =
    endpoints ?
      (
        <ul className="list-unstyled">
          {Object.entries(endpoints).map(([, attributes]) => {
            const methodColor = getHttpMethodColor(attributes.method);
            const endpointClass = `fw-bold text-${methodColor}`;
            return (
              <li>
                <a href={attributes.link} className="text-decoration-none text-black">
                  <span className={endpointClass}>
                    {attributes.method}
                  </span>
                  <span> </span>
                  <span>{attributes.endpoint}</span>
                </a>
              </li>
            );
          })}
        </ul>
      ) : null;

  const responseContent = displayJson(data);

  const sectionA = (
    <>
      <h3>{header}</h3>
      <div className="pt-16">
        <h4>Attributes</h4>
        <hr />
        <Attributes
          model={model}
        />
      </div>
    </>
  );

  const sectionB = (
    <RequestExample
      requestTitle="ENDPOINTS"
      responseTitle={title.toUpperCase()}
      requestContent={requestContent}
      responseContent={responseContent}
    />
  );

  return (
    <Section
      sectionA={sectionA}
      sectionB={sectionB}
    />
  );
};

export default ObjectSection;
