/* eslint-disable array-callback-return */
import { Row, Col, Container } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import convertToDateTime from '../../../utils/convertToDateTime';
import StatusPill from './statusPill';

const LogsView = ({
  tempData,
  datesArray,
  handleSetId,
  activeObj,
  currentTab,
}) => {
  const failedStatusCodes = [400, 401, 402, 403, 404, 429, 500, 502, 503, 504];
  const succeededStatusCodes = [200, 201, 202, 203, 204, 205, 206, 207];
  let filteredDateNames;
  let filteredData = [];
  if (currentTab === 'all') {
    filteredData = tempData;
    filteredDateNames = datesArray;
  } else if (currentTab === 'succeeded') {
    filteredData = tempData?.filter(obj => succeededStatusCodes.includes(obj.status));
    const tempArray = [];
    filteredData?.forEach((data) => {
      datesArray?.forEach((date) => {
        if (date === convertToDateTime(data?.createdAt, 'dateOnly') && !tempArray.includes(date)) {
          tempArray.push(date);
        }
      });
    });
    filteredDateNames = tempArray;
  } else {
    filteredData = tempData?.filter(obj => failedStatusCodes.includes(obj.status));
    const tempArray = [];
    filteredData?.forEach((data) => {
      datesArray?.forEach((date) => {
        if (date.original === data?.date && !tempArray.includes(date)) {
          tempArray.push(date);
        }
      });
    });
    filteredDateNames = tempArray;
  }

  return (
    <Row>
      <Col sm={6} className="border-end pe-0">
        <Container fluid className="p-0 fw-700">
          {filteredDateNames && filteredDateNames?.map((dateNames) => (
            <div key={dateNames}>
              <div className="border-bottom py-8 mb-0 pe-0">{dateNames}</div>
              <div>
                {filteredData?.map((data) => (
                  convertToDateTime(data?.createdAt, 'dateOnly') === dateNames && (
                    <Row
                      className={`m-0 hover ${activeObj === data ? ('border-start border-blue border-3 bg-light-grey') : ('border-bottom')}`}
                      key={data?.id}
                      onClick={() => handleSetId(data)}
                    >
                      <Col sm={2} className="d-flex align-items-center px-lg-0 px-xl-16">
                        <StatusPill status={data?.status} />
                      </Col>
                      <Col sm={2} className="py-8 d-flex align-items-center">
                        {data?.req?.method}
                      </Col>
                      <Col sm={5} className="py-8 d-flex align-items-center">
                        {data?.req?.pathUrl}
                      </Col>
                      <Col sm={3} className="py-8 d-flex align-items-center">
                        {convertToDateTime(data?.createdAt, 'dateOnly')}
                        <br />
                        {convertToDateTime(data?.createdAt, 'timeOnly')}
                      </Col>
                    </Row>
                  )
                ))}
              </div>
            </div>
          ))}
          {/* {tempData.length === fetchAmount && (
          <Button
            className="border-0 col-12"
            variant="outline-dark-blue"
            onClick={() => fetchMoreLogs()}
          >
            <FontAwesomeIcon icon={faArrowDown} className="me-8" />
            LOAD MORE
          </Button>
          )} */}
        </Container>
      </Col>
      <Col sm={6} className="p-16">
        {filteredData.includes(activeObj) && (
          <>
            <h2 className="fw-700">
              {activeObj?.req?.method}
              {' '}
              {activeObj?.req?.pathUrl}
            </h2>
            <Row className="pt-8">
              <Col sm={5} className="fw-400">
                <h5>Status</h5>
              </Col>
              <Col sm={3} className="fw-400 p-0 ms-16">
                <StatusPill status={activeObj?.status} />
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="fw-400">
                <h5>ID</h5>
              </Col>
              <Col sm={7} className="fw-400">
                <h5>{activeObj?.id}</h5>
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="fw-400">
                <h5>Time</h5>
              </Col>
              <Col sm={7} className="fw-400">
                <h5>{convertToDateTime(activeObj?.createdAt)}</h5>
              </Col>
            </Row>
            <Row>
              <Col sm={5} className="fw-400">
                <h5>API Name</h5>
              </Col>
              <Col sm={7} className="fw-400">
                <h5 className="text-dark-blue">{activeObj?.apiVersion}</h5>
              </Col>
            </Row>
            <div className="border-bottom p-8 mb-16" />
            <h2 className="text-dark-blue fw-500 my-16">Request</h2>
            <ReactJson
              name={null}
              src={activeObj?.req}
              displayObjectSize={false}
              displayDataTypes={false}
            />
            <h2 className="text-dark-blue fw-500 my-16">Response</h2>
            <ReactJson
              name={null}
              src={activeObj?.res}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default LogsView;
