import React, { useState } from 'react';
import { Card, Container, Image, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import loginLogo from '../../../images/OG-LoginLogo.svg';
import loginBackground from '../../../images/loginBackground.svg';
import AppContainer from '../../global/AppContainer';
import { auth } from '../../providers/FirebaseProvider/firebase';
import { useAlert } from '../../providers/AlertProvider/AlertProvider';
import validateEmail from '../../utils/validateEmail';

const ResetPassword = () => {
  const setAlert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleResetPassword = async () => {
    try {
      setIsLoading(true);
      const result = await auth.sendPasswordResetEmail(email);
      if (result) {
        setAlert({
          message: `A link to reset your password was sent to ${email}.`,
        });
        window.location.href = '/login';
      } else {
        setAlert({
          message: 'This user doesn not exist',
        });
      }
    } catch (error) {
      setAlert({
        message: 'Unable to send link',
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AppContainer isLoading={isLoading} loadingMessage="Verifying...">
      <div className="d-flex align-items-center justify-content-center">
        <Image src={loginLogo} alt="login Logo" className="position-absolute mt-112 " />
      </div>
      <Image src={loginBackground} alt="login Background" className="position-absolute bottom-0 end-0" />
      <Container className="vh-100 d-flex align-items-center justify-content-center ">
        <Card className="shadow-lg bg-body rounded py-xl-64 px-xl-112 py-lg-40 px-lg-64 py-md-24 px-md-40 w-50">
          <h2 className="fw-700">Reset your password</h2>
          {/* eslint-disable-next-line max-len */}
          <p className="me-72"> Enter the email address associated with your account and we&apos;ll send you a link to reset your password. </p>
          <Form.Group className="form-group mt-24">
            <Form.Label htmlFor="inputEmail">
              Email Address
              {!validateEmail(email) && (
              <small className="text-danger fw-700 ps-8">Invalid email</small>
              )}
            </Form.Label>
            <Form.Control
              className="form-control form-control-sm"
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              className="d-flex w-100 justify-content-center fw-700 py-8 mt-16"
              type="submit"
              variant="dark-blue"
              disabled={!email || !validateEmail(email)}
              onClick={() => handleResetPassword()}
            >
              Continue
            </Button>
            <h4 className="fw-400 d-flex mt-40 mb-0 justify-content-center">
              <Link to="/login" className="ms-8">Return to sign in</Link>
            </h4>
          </Form.Group>
        </Card>
      </Container>
    </AppContainer>
  );
};

export default ResetPassword;
