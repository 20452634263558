import { Button, Form, Modal } from 'react-bootstrap';

const EditUserModal = ({
  show,
  handleClose,
  selectedMember,
  onSelectedMemberChange,
  handleSelectedMemberSave,
}) => (
  <Modal show={show} onHide={() => handleClose()}>
    <Modal.Header closeButton>
      <Modal.Title>
        Edit
        {' '}
        {selectedMember?.userData?.name}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group className="form-group">
        <Form.Label htmlFor="inputFullName">Full Name</Form.Label>
        <Form.Control
          className="form-control form-control-sm"
          type="text"
          name="name"
          value={selectedMember?.userData?.name}
          onChange={onSelectedMemberChange}
        />
        <Form.Label htmlFor="inputUserType" className="mt-16">Role</Form.Label>
        <Form.Select
          className="form-control form-control-sm"
          type="text"
          name="userType"
          value={selectedMember?.userData?.userType}
          onChange={onSelectedMemberChange}
        >
          <option>Select</option>
          <option value="Developer">Developer</option>
          <option value="Administrator">Administrator</option>
        </Form.Select>
        <div className="mt-16">
          <Form.Check
            className="py-8 pe-8 d-inline"
            type="checkBox"
            checked={selectedMember?.userData?.isActive}
            onChange={onSelectedMemberChange}
          />
          <Form.Label htmlFor="inputStaySignedIn">Is Active</Form.Label>
        </div>
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="dark-blue" onClick={handleSelectedMemberSave}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
);

export default EditUserModal;
