const Loader = ({
  message = '',
  className,
}) => (
  <div className={className}>
    <div className="loader" width="100" />
    <h4 className="mt-16">{message || 'Loading...'}</h4>
  </div>
);

export default Loader;
