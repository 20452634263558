import { HttpMethod } from '../../../../../../constants';

const version = 'v2';
const baseUrl = 'funders';
const docLink = `docs/api/${version}`;

const endpointIds = {
  object: {
    id: 'funder_object',
  },
  get: {
    id: 'funder_retrieval',
  },
  getAll: {
    id: 'funder_list',
  },
  search: {
    id: 'funder_search',
  },
};

const model = {
  header: 'Funders',
  title: 'The Funder Object',
  object: endpointIds.object.id,
  link: `/docs/api/v2#${endpointIds.object.id}`,
};

const attributes = {
  id: { type: 'int', description: 'The funder\'s unique identifier.' },
  name: { type: 'string', description: 'The funder\'s name' },
  description: { type: 'string', description: 'The funder\'s description' },
  locations: {
    type: 'array of strings',
    description:
      (
        <>
          {'The grant\'s applicable locations. Some possbile values are Federal, International, and states in the united states.'}
          <span> </span>
          <a href={`/docs/api/${version}#filter_object`}>
            See Filter Options to retrieve all possible options.
          </a>
        </>
      ),
  },
  website: { type: 'string', description: 'The funder\'s website' },
};

const endpoints = {
  get: {
    id: endpointIds.get.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.get.id}`,
    endpoint: `/${baseUrl}/{id}`,
    example: `/${baseUrl}/8523`,
    title: 'Retrieve a Funder',
    description: 'Retrieve a funder by the funder id',
    returnContent: 'Returns the Funder object if the call succeeded',
  },
  getAll: {
    id: endpointIds.getAll.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.getAll.id}`,
    endpoint: `/${baseUrl}/all`,
    example: `/${baseUrl}/all`,
    title: 'Retrieve All Funders',
    description: 'Retrieve all the funders from the OpenGrants database',
    returnContent: 'Returns an array of Grant objects and a totalResult property containing the total count of the data.',
  },
  search: {
    id: endpointIds.search.id,
    method: HttpMethod.GET,
    link: `/${docLink}#${endpointIds.search.id}`,
    endpoint: `/${baseUrl}`,
    example: `/${baseUrl}?query=grants%20water&page=`,
    title: 'Search Funders',
    description: 'Searches and returns an array of funders from the OpenGrants database via pagination',
    returnContent: 'Returns a Pagination object with the data property containing an array of Funder objects.',
    queryParameters: {
      query: { type: 'optional', description: 'Search grant and funder title description for any mention of the filter text.' },
      page: { type: 'optional', description: 'Retrieve the grants from the specifed page.' },
      filterFacets: {
        type: 'optional',
        description: (
          <>
            Filter grants by facets
            <br />
            <br />
            Possible facet attributes is locations
            <br />
            <br />
            To filter by text value, set facetFilters=[
            {'<options>'}
            ] where
            {' <options> '}
            is one or a combination of the following:
            <ul>
              <li>&quot;attribute:value&quot; for single filtering</li>
              <li>
                &quot;attribute:value&quot;,&quot;attribute:value&quot;
                for filtering by AND
              </li>
              <li>
                [&quot;attribute:value&quot;,&quot;attribute:value&quot;]
                for filtering by OR
              </li>
            </ul>
            Attribute is one of the possible facet attributes mentioned
            above and value is the value you would like to filter by.
            Note that the attribute and the value are match-case.
            This means that the attribute and value must match the options
            exactly to get the expected returns.
            <span> </span>
            <a href={`/docs/api/${version}#filter_object`}>
              See Filter Options to retrieve possible options for each of the attribute
            </a>
            <br />
            <br />
            Example (filter by single facet):
            <br />
            facetFilters=[&quot; locations: California&quot;]
            <br />
            <br />
            Example (filter by multiple facet AND):
            <br />
            facetFilters=[&quot; locations: California&quot;,
            &quot;locations: Michigan&quot;]
            <br />
            <br />
            Example (filter by multiple facet OR):
            <br />
            facetFilters=[[&quot; locations: California&quot;,
            &quot;locations: Michigan&quot;]]
          </>
        ),
      },
    },
  },
};

export {
  model,
  attributes,
  endpoints,
};
